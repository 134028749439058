import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { circleDescriptionSvg } from './circleSvg';

const CircleDescription = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const circleDescSvg = useRef();

  useEffect(() => {
    const scrollArea = document.getElementById('ScrollArea');

    const handleScroll = () => {
      // Calculate the scroll distance
      const scrollTop = scrollArea.scrollTop;
      setScrollPosition(scrollTop);
    };

    // Attach scroll event listener
    scrollArea.addEventListener('scroll', handleScroll);

    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      scrollArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate the rotation angle based on the scroll position
  const rotationAngle = scrollPosition * 0.1; // You can adjust the factor as needed

  const { rotation } = useSpring({
    from: { rotation: 0 },
    to: { rotation: rotationAngle },
    config: { mass: 3, tension: 50, friction: 30, damping: 1 },
  });

  return (
    <>
      <animated.svg
        xmlns="http://www.w3.org/2000/svg"
        width={375}
        height={375}
        viewBox="0 0 375 375"
        className={'circleDescription__svg'}
        ref={circleDescSvg}
        {...props}
        style={{
          transform: rotation.to((angle) => `rotate(${angle}deg)`),
        }}
      >
        <path d={circleDescriptionSvg.path} />
      </animated.svg>
    </>
  );
};
export default CircleDescription;
