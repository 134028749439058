import { WebGLRenderTarget, Object3D } from 'three';
import React, { useRef, useMemo, useLayoutEffect } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import lerp from 'lerp';
import BackfaceMaterial from './BackfaceMaterial';
import RefractionMaterial from './RefractionMaterial';
import { useBlock } from '../../../../ui/components/blocks';
import state from '../../../../ui/const/store';

const THREEDUMMYOBJECT = new Object3D();
export default function Diamonds() {
  const { nodes } = useLoader(GLTFLoader, '/bolt.glb');
  useLayoutEffect(() => {
    nodes.bolt01.geometry.center();
  }, [nodes.bolt01.geometry]);

  const { size, gl, scene, camera, clock } = useThree();
  const { contentMaxWidth, sectionHeight, mobile } = useBlock();
  const THREEMODEL = useRef();
  const PIXELRATIO = gl.getPixelRatio();

  const [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial] =
    useMemo(() => {
      const envFbo = new WebGLRenderTarget(
        size.width * PIXELRATIO,
        size.height * PIXELRATIO,
      );
      const backfaceFbo = new WebGLRenderTarget(
        size.width * PIXELRATIO,
        size.height * PIXELRATIO,
      );
      const backfaceMaterial = new BackfaceMaterial();
      const refractionMaterial = new RefractionMaterial({
        envMap: envFbo.texture,
        backfaceMap: backfaceFbo.texture,
        resolution: [size.width * PIXELRATIO, size.height * PIXELRATIO],
      });
      return [envFbo, backfaceFbo, backfaceMaterial, refractionMaterial];
    }, [size, PIXELRATIO]);

  useFrame(() => {
    state.diamonds.forEach((diamondsData, i) => {
      const ELAPSEDTIME = clock.getElapsedTime() / 2;
      const { x, offset, scale, factor } = diamondsData;
      const SCALEVALUE = (contentMaxWidth / 35) * scale;
      const s = SCALEVALUE;
      const t = ELAPSEDTIME;

      diamondsData.pos.set(
        mobile ? 0 : x,
        lerp(
          diamondsData.pos.y,
          -sectionHeight * offset * factor +
            (state.top.current / state.zoom) * factor,
          0.075,
        ),
        0,
      );

      THREEDUMMYOBJECT.position.copy(diamondsData.pos);

      if (i === state.diamonds.length - 3 || i === state.diamonds.length - 2) {
        THREEDUMMYOBJECT.rotation.set(0.125, t / 2, 0);
      } else THREEDUMMYOBJECT.rotation.set(t, t / 2, t / 2);

      THREEDUMMYOBJECT.scale.set(s, s, s);
      THREEDUMMYOBJECT.updateMatrix();
      THREEMODEL.current.setMatrixAt(i, THREEDUMMYOBJECT.matrix);
      THREEMODEL.current.instanceMatrix.needsUpdate = true;
    });

    gl.autoClear = false;
    camera.layers.set(0);
    gl.setRenderTarget(envFbo);
    gl.clearColor();
    gl.render(scene, camera);
    gl.clearDepth();
    camera.layers.set(1);
    THREEMODEL.current.material = backfaceMaterial;
    gl.setRenderTarget(backfaceFbo);
    gl.clearDepth();
    gl.render(scene, camera);
    camera.layers.set(0);
    gl.setRenderTarget(null);
    gl.render(scene, camera);
    gl.clearDepth();
    camera.layers.set(1);
    THREEMODEL.current.material = refractionMaterial;
    gl.render(scene, camera);
  }, 1);

  return (
    <instancedMesh
      ref={THREEMODEL}
      layers={1}
      args={[nodes.bolt01.geometry, null, state.diamonds.length]}
      position={[0, 0, 0]}
    />
  );
}
