import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';

const ScrollToTopButton = () => {
  const topBtnRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const scrollArea = document.getElementById('ScrollArea');

    const handleScroll = () => {
      // Calculate the scroll distance
      const scrollTop = scrollArea.scrollTop;
      setScrollPosition(scrollTop);
    };

    // Attach scroll event listener
    scrollArea.addEventListener('scroll', handleScroll);

    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      scrollArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate whether to show the button based on the scroll position
  const shouldShowButton = scrollPosition > 300;

  const { opacityStyle, yPositionStyle, pointerEventStyle } = useSpring({
    opacityStyle: shouldShowButton ? 1 : 0,
    yPositionStyle: shouldShowButton ? '0%' : '-50%',
    pointerEventStyle: shouldShowButton ? 'all' : 'none',
    config: { mass: 10, tension: 150, friction: 100, damping: 2 },
  });

  return (
    <animated.a
      ref={topBtnRef}
      href="/#Start"
      className={'scrollToTopButton__link'}
      style={{
        pointerEvents: pointerEventStyle.to((o) => o),
        opacity: opacityStyle.to((o) => o),
        display: opacityStyle.to((o) => (o === 0 ? 'none' : 'flex')),
        transform: yPositionStyle.to((y) => `translateY(${y})`),
      }}
    >
      <div className="chevron__container chevron__container--scrollTop">
        <div className="chevron" />
        <div className="chevron" />
        <div className="chevron" />
      </div>
    </animated.a>
  );
};

export default ScrollToTopButton;
