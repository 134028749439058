import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion/dist/framer-motion';
import { logo } from '../const/data';

const LoadingSvg = (props) => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots === '...' ? '' : prevDots + '.'));
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="loading__container">
      <motion.svg
        className="loading__svg"
        viewBox={logo.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <motion.path
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 0.6,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0,
          }}
          strokeWidth={3}
          strokeDasharray="0 1"
          d={logo.svg}
        />
      </motion.svg>
      <span className="loading__text">{`Loading${dots}`}</span>
    </div>
  );
};

export default LoadingSvg;
