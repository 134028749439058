import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as React from 'react';
import ReactDOM from 'react-dom';
import Layout from './ui/components/layout';
import Home from './pages/home';
import NoMatch404 from './pages/404';

export default function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout />}
      >
        <Route
          index
          element={<Home />}
        />
        <Route
          path="*"
          element={<NoMatch404 />}
        />
      </Route>
    </Routes>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
