import * as THREE from 'three';
import React, { useCallback, useRef } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import { useAsset } from 'use-asset';
import lerp from 'lerp';
import state from '../../../ui/const/store';

function Text({
  children,
  size = 1,
  left,
  right,
  top,
  bottom,
  color = 'white',
  opacity = 1,
  height = 0.01,
  layers = 0,
  fontStyle,
  ...props
}) {
  const loadedFont = () =>
    fontStyle === 1 ? '/NeonX_Regular.blob' : '/NeonX_Italic.blob';
  const THREEFONTDATA = useLoader(THREE.FontLoader, loadedFont());
  const TEXTGEOM = useAsset(
    () =>
      new Promise((res) =>
        res(
          new THREE.TextGeometry(children, {
            font: THREEFONTDATA,
            size: 1,
            height,
            curveSegments: 512,
          }),
        ),
      ),
    [children],
  );
  const onUpdate = useCallback(
    (self) => {
      const box = new THREE.Vector3();
      self.geometry.computeBoundingBox();
      self.geometry.boundingBox.getSize(box);
      self.position.x = left ? 0 : right ? -box.x : -box.x / 2;
      self.position.y = top ? 0 : bottom ? -box.y : -box.y / 2;
    },
    [left, right, top, bottom],
  );

  const CUSTOMMATERIALREF = useRef();
  let LASTSTATE = state.top.current;
  useFrame(() => {
    CUSTOMMATERIALREF.current.shift = lerp(
      CUSTOMMATERIALREF.current.shift,
      (state.top.current - LASTSTATE) / 50,
      0.25,
    );
    LASTSTATE = state.top.current;
  });
  return (
    <group
      {...props}
      scale={[size, size, 0.1]}
    >
      <mesh
        geometry={TEXTGEOM}
        onUpdate={onUpdate}
        frustumCulled={false}
      >
        <customMaterial
          ref={CUSTOMMATERIALREF}
          color={color}
          transparent
          opacity={opacity}
        />
      </mesh>
    </group>
  );
}

const MultilineText = ({
  text,
  size = 1,
  lineHeight = 1,
  position = [0, 0, 0],
  ...props
}) =>
  text.split('\n').map((text, index) => (
    <Text
      key={index}
      size={size}
      {...props}
      position={[position[0], position[1] - index * lineHeight, position[2]]}
      children={text}
    />
  ));

export { Text, MultilineText };
