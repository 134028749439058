import { createRef } from 'react';
import { Vector3 } from 'three';
import Timeline from '../components/timeline';

const state = {
  sections: 6,
  pages: 6,
  zoom: 75,
  paragraphImage: [
    {
      offset: 0.65,
      factor: 2,
      headline: 'Hello, World!',
      image: '/profile_pic_vL.jpg',
      imgWidth: 12.5,
      imgY: 0.75,
      aspect: 1.5,
      indexText: 'About me',
      indexTextOffset: 10,
      indexBasePosition: -20,
      text: {
        headline: '',
        content: `
        <p>
          I'm a full-stack web developer & designer from Berlin, Germany. I've always been drawn to the internet from a young age and found out along the way, I enjoy creating awesome websites and components, reading tech blogs and opensource code, paint and draw digitally, play and develop computer games and such.
        </p>
        <p>
          Professionally, I like to engage with a hands-on mindset in the whole cycle of web development. From conception to completion, ensuring alignment with the intended vision, diverse criteria and common coding standards.
        </p>
        <p>
          With a background as a trained media designer and about a decade of IT-industry experience, my prime objective lies in bridging frontend platforms and backend services, while balancing code and design excellence and maintainging a clean, scalable, developer-friendly software architecture.
        </p>
        <p>
          I believe in a holistic web development strategy, emphasizing user-centric principles and technical excellence for wholesome web experiences. For example, I've worked on projects where we put the user at the center of the design process, conducting usability testing and making iterative improvements based on user feedback. This user-centric approach not only improved user satisfaction but also led to better engagement and conversion potential.
        </p>
        <p>
          I transform digital concepts into performant web applications, emphasizing  design with contemporary style elements to create a meaningful impact on the World Wide Web.
        </p>
        <img src="https://res.cloudinary.com/ollip-dev/image/upload/v1697580589/signature_wxszep.png" width="200" height="100" />
        `,
      },
    },
  ],
  stripes: [{ offset: 3.635, backgroundColor: '#ffb727', height: 12 }],
  diamonds: [
    { x: -5.75, offset: 0.125, pos: new Vector3(), scale: 0.066, factor: 6 },
    { x: 5, offset: 0.859, pos: new Vector3(), scale: 0.0125, factor: 2.1 },
    { x: 0, offset: 2.85, pos: new Vector3(), scale: 0.015, factor: 2.5 },
  ],
  top: createRef(),
  paragraphText: [
    {
      offset: 1.65,
      factor: 2,
      headline: 'Work Experience',
      aspect: 1.5,
      indexText: 'Career',
      indexTextOffset: 10,
      indexBasePosition: -20,
      text: {
        content: <Timeline />,
      },
    },
    {
      offset: 4.5,
      factor: 2,
      headline: 'Tech Stack',
      aspect: 1.5,
      indexText: 'Skills',
      indexTextOffset: 18.75,
      indexBasePosition: 35,
      text: {
        content: `
        <section>
        <h2>Section 1</h2>
        <p>This is some dummy content for section 1. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
    </section>

    <section>
        <h2>Section 2</h2>
        <p>This is some dummy content for section 2. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
    </section>

    <section>
        <h2>Section 3</h2>
        <p>This is some dummy content for section 3. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</p>
    </section>
        `,
      },
    },
    {
      offset: 5.1,
      factor: 2,
      headline: `Let's connect`,
      aspect: 1.5,
      indexText: 'Contacts',
      indexTextOffset: -27,
      indexBasePosition: -45,
      text: {
        headline: 'Lorem ipsum dolor sit amet',
        subHeadline:
          'Ad sed diam nonumy eirmod tempor invidunt ut labore et dolore.',
        content:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      },
    },
  ],
  timelineText: [
    {
      description: 'Lorem Ipsum dolor sit amet',
      date: '2009',
      text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
    },
    {
      description: 'Lorem Ipsum dolor sit amet',
      date: '2013 - 2016',
      text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
    },
    {
      description: 'Lorem Ipsum dolor sit amet',
      date: '2016 - 2017',
      text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
    },
    {
      description: 'Lorem Ipsum dolor sit amet',
      date: '2018 - 2020',
      text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
    },
    {
      description: 'Lorem Ipsum dolor sit amet',
      date: '2022 - Now',
      text: `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.`,
    },
  ],
};

export default state;
