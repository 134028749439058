import React, { forwardRef, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import lerp from 'lerp';
import './CustomMaterial';
import { useBlock } from '../../../ui/components/blocks';
import state from '../../../ui/const/store';

const Plane = forwardRef(
  ({ color = 'white', shift = 1, opacity, args, map, ...props }, ref) => {
    const { viewportHeight, offsetFactor } = useBlock();
    const CUSTOMMATERIALREF = useRef();
    let LASTSTATE = state.top.current;
    useFrame(() => {
      const { pages, top } = state;
      CUSTOMMATERIALREF.current.scale = lerp(
        CUSTOMMATERIALREF.current.scale,
        offsetFactor - (top.current / ((pages - 1) * viewportHeight)) * 1,
        0.1,
      );
      CUSTOMMATERIALREF.current.shift = lerp(
        CUSTOMMATERIALREF.current.shift,
        (top.current - LASTSTATE) / shift,
        0.0666,
      );
      LASTSTATE = top.current;
    });

    return (
      <mesh
        ref={ref}
        {...props}
      >
        <planeBufferGeometry args={args} />
        <customMaterial
          ref={CUSTOMMATERIALREF}
          color={color}
          map={map}
          transparent
          opacity={opacity}
        />
      </mesh>
    );
  },
);

export default Plane;
