import React from 'react';
import LightningEffect from './lightningEffect';

const ContactBtn = () => {
  return (
    <div className="voltButton">
      <a
        className="contactBtn button button--border"
        href="mailto:ollipaust@gmail.com?subject=Hello&body=Hello Olli, nice to meet you!"
      >
        Get in touch!
      </a>
      <LightningEffect />
    </div>
  );
};

export default ContactBtn;
