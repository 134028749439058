import React, { useEffect, useRef } from 'react';
import { animated, config, useTrail } from 'react-spring';

import ContactBtn from './contactBtn';

export default function LayoutHeader() {
  const navbarRef = useRef();

  useEffect(() => {
    const scrollArea = document.getElementById('ScrollArea');

    const handleScroll = () => {
      const scrollTop = scrollArea.scrollTop;
      const navBar = navbarRef;

      if (scrollTop >= 200) {
        navBar.current.classList.add('header--blur');
      } else {
        navBar.current.classList.remove('header--blur');
      }
    };

    scrollArea.addEventListener('scroll', handleScroll);

    return () => {
      scrollArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const links = [
    { name: 'Start', href: '/#Start' },
    { name: 'About', href: '/#About' },
    { name: 'Experience', href: '/#Experience' },
    { name: 'Skills', href: '/#Skills' },
    { name: 'Contact', component: ContactBtn },
  ];

  const trailLeft = useTrail(links.length, {
    config: { mass: 5, tension: 500, friction: 50, damping: 5 },
    from: { opacity: 0, transform: 'translateX(-20px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    delay: 2000, // Apply staggered delay to all links
  });
  const trailRight = useTrail(links.length, {
    config: config.molasses,
    from: { opacity: 0, transform: 'translateX(50px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
    delay: 2250, // Apply staggered delay to all links
  });

  return (
    <header
      id="NavBar"
      className="header"
      ref={navbarRef}
    >
      <div className="header__left">
        <animated.div
          className="logo voltButton"
          style={trailLeft[0]}
        >
          <a
            className="button button--visible"
            href="/"
          >
            <span className="logo__text">op.dev</span>
          </a>
        </animated.div>
      </div>
      <div className="header__right">
        <nav className="navigation">
          <ul>
            {links.map((link, index) => (
              <animated.li
                key={index}
                style={trailRight[index]}
              >
                {link.component ? (
                  <ContactBtn />
                ) : (
                  <a href={link.href}>{link.name}</a>
                )}
              </animated.li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
}
