import React, { useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { useInView } from 'react-intersection-observer';
import lerp from 'lerp';

import { Text } from './Text';
import Plane from './Plane';
import { Block, useBlock } from '../../../ui/components/blocks';
import state from '../../../ui/const/store';

export default function ParagraphWithImage({
  image,
  index,
  offset,
  factor,
  headline,
  aspect,
  indexText,
  indexTextOffset,
  indexBasePosition,
  text,
}) {
  const { contentMaxWidth: w, canvasWidth, margin, mobile } = useBlock();
  const [indexTextPosition, setIndexTextPosition] = useState(0);
  const RESPONSIVE_SIZE = aspect < 1 && !mobile ? 0.65 : 0.9;
  const ALIGN_RIGHT = (canvasWidth - w * RESPONSIVE_SIZE - margin) / 2;
  const ALIGNED_LEFT = !(index % 2);
  const IMAGE_SIZE = w * RESPONSIVE_SIZE;
  const TEXT_SIZE = indexText.length > 6 ? w * 0.175 : w * 0.25;
  const PIXEL_WIDTH = w * state.zoom * RESPONSIVE_SIZE;
  const HEADLINECOLOR = '#ffb727';

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '-100px',
  });

  useFrame(() => {
    const SCROLL_PERCENTAGE =
      state.top.current / (state.pages * window.innerHeight);
    const EFFECT_FACTOR = 2;
    const NEW_TEXT_POSITION = lerp(
      indexTextOffset,
      indexBasePosition,
      SCROLL_PERCENTAGE * EFFECT_FACTOR,
    );

    setIndexTextPosition(NEW_TEXT_POSITION);
  });
  return (
    <Block
      factor={factor}
      offset={offset}
    >
      <group position={[0, 0, 0]}>
        <Plane
          map={image}
          args={[1, 1, 32, 32]}
          shift={75}
          size={RESPONSIVE_SIZE}
          aspect={aspect}
          scale={[IMAGE_SIZE, IMAGE_SIZE / aspect, 1]}
          frustumCulled={false}
        />
        <Html
          ref={ref}
          className="contentArea"
          style={{
            width: PIXEL_WIDTH / (mobile ? 1 : 1.5),
            textAlign: ALIGNED_LEFT ? 'left' : 'right',
            opacity: inView ? 1 : 0,
          }}
          position={[
            ALIGNED_LEFT || mobile ? (-w * RESPONSIVE_SIZE) / 2 : -2,
            (-w * RESPONSIVE_SIZE) / 2 / aspect - 0.4,
            1,
          ]}
        >
          <div
            id=""
            className="paragraphText"
          >
            {text.headline && text.headline.length > 0 && (
              <div>{text.headline}</div>
            )}
            {text.subHeadline && text.subHeadline.length > 0 && (
              <h3 dangerouslySetInnerHTML={{ __html: text.subHeadline }} />
            )}
            {text.content && text.content.length > 0 && (
              <>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: text.content }}
                />
              </>
            )}
            {text.subContent && text.subContent.length > 0 && (
              <div
                className="subContent"
                dangerouslySetInnerHTML={{ __html: text.subContent }}
              />
            )}
          </div>
        </Html>
        <Text
          fonts={true}
          size={w * 0.075}
          color={HEADLINECOLOR}
          top
          position={[0, (w * RESPONSIVE_SIZE) / aspect / 2 + 2, -1]}
        >
          {headline}
        </Text>
      </group>
      <group position={[ALIGNED_LEFT ? -ALIGN_RIGHT : ALIGN_RIGHT, 0, 0]}>
        <Block factor={0.2}>
          <Text
            opacity={1}
            size={TEXT_SIZE}
            color="#181919"
            position={[
              indexTextPosition,
              (w * RESPONSIVE_SIZE) / aspect / 1.15,
              -5,
            ]}
          >
            {indexText}
          </Text>
        </Block>
      </group>
    </Block>
  );
}
