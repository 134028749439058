import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import lerp from 'lerp';
import Plane from '../../pages/landingPage/components/Plane';

export default function Startup() {
  const PLANEREF = useRef();
  useFrame(
    () =>
      (PLANEREF.current.material.opacity = lerp(
        PLANEREF.current.material.opacity,
        0,
        0.1,
      )),
  );
  return (
    <Plane
      ref={PLANEREF}
      color="#FFB727"
      position={[0, 0, 100]}
      scale={[100, 100, 1]}
    />
  );
}
