import React from 'react';
import { useInView } from 'react-intersection-observer';
import state from '../const/store';

// Separate component for each timeline item
const TimelineItem = ({ timelineTexts, index }) => {
  const ALIGN = !(index % 2) ? 'left' : 'right';
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-300px',
  });

  return (
    <div
      ref={ref}
      className={`timelineSlide timelineSlide--${ALIGN}`}
      style={{
        transform: inView
          ? `translateX(${index % 2 === 0 ? '10%' : '-10%'})`
          : `translateX(0)`,
      }}
    >
      <div
        className={`timelineDesc timelineDesc--${ALIGN} largeText`}
        style={{
          opacity: inView ? 1 : 0,
        }}
      >
        <p>{timelineTexts.description}</p>
      </div>
      <div
        className="timelineDate timelineDate--alt"
        id={timelineTexts.date}
        style={{
          opacity: inView ? 1 : 0,
        }}
      >
        <p>{timelineTexts.date}</p>
      </div>
      <div
        className={`timelineText timelineText--${ALIGN}`}
        style={{
          opacity: inView ? 1 : 0,
        }}
      >
        <p>{timelineTexts.text}</p>
      </div>
    </div>
  );
};

// Main Timeline component
export default function Timeline() {
  return (
    <div className="timelineSlide__container">
      {state.timelineText.map((timelineTexts, index) => (
        <TimelineItem
          key={index}
          timelineTexts={timelineTexts}
          index={index}
        />
      ))}
    </div>
  );
}
