import React, { useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { useInView } from 'react-intersection-observer';
import lerp from 'lerp';
import { Text } from './Text';
import { Block, useBlock } from '../../../ui/components/blocks';
import state from '../../../ui/const/store';
import Timeline from '../../../ui/components/timeline';
import TimelineLightning from '../../../ui/components/timelineLightning';

export default function ParagraphHtml({
  index,
  indexText,
  indexTextOffset,
  indexBasePosition,
  offset,
  factor,
  headline,
  aspect,
  text,
}) {
  const [indexTextPosition, setIndexTextPosition] = useState(0);
  const { contentMaxWidth: w, mobile } = useBlock();
  const RESPONSIVE_SIZE = aspect < 1 && !mobile ? 0.65 : 0.9;
  const ALIGNED_LEFT = !(index % 2);
  const PIXEL_WIDTH = w * state.zoom * RESPONSIVE_SIZE;
  const HEADLINECOLOR = '#ffb727';
  const TEXT_SIZE = indexText.length > 6 ? w * 0.175 : w * 0.25;

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: index === 1 ? '-300px' : '300px',
  });

  useFrame(() => {
    const SCROLL_PERCENTAGE =
      state.top.current / (state.pages * window.innerHeight);
    const EFFECT_FACTOR = 3;
    const NEW_TEXT_POSITION = lerp(
      indexBasePosition,
      indexTextOffset,
      SCROLL_PERCENTAGE * EFFECT_FACTOR,
    );
    setIndexTextPosition(NEW_TEXT_POSITION);
  });

  return (
    <Block
      factor={factor}
      offset={offset}
    >
      <group position={[0, 0, 0]}>
        <Html
          occlude="blending"
          ref={ref}
          className="contentArea"
          style={{
            width: PIXEL_WIDTH / (mobile ? 1 : 1),
            textAlign: ALIGNED_LEFT ? 'right' : 'left',
            opacity: inView ? 1 : 0,
          }}
          position={[
            ALIGNED_LEFT || mobile ? (-w * RESPONSIVE_SIZE) / 2 : -2,
            (w * RESPONSIVE_SIZE) / aspect / 4,
            0,
          ]}
        >
          <div
            id="ParagraphText"
            className="paragraphText"
          >
            {text.headline && text.headline.length > 0 ? (
              <div className="paragraphText__header">
                <h2 dangerouslySetInnerHTML={{ __html: text.headline }} />
                {text.subHeadline && text.subHeadline.length > 0 && (
                  <h3 dangerouslySetInnerHTML={{ __html: text.subHeadline }} />
                )}
              </div>
            ) : null}
            {text.content && text.content.length > 0 && (
              <>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: text.content }}
                />
              </>
            )}
            {text.subContent && text.subContent.length > 0 && (
              <div
                className="subContent"
                dangerouslySetInnerHTML={{ __html: text.subContent }}
              />
            )}
            {index === 0 && (
              <>
                <Timeline />
              </>
            )}
          </div>
        </Html>
        <Text
          fonts={true}
          size={w * 0.075}
          color={HEADLINECOLOR}
          top
          position={[0, (w * RESPONSIVE_SIZE) / aspect / 2, -1]}
        >
          {headline}
        </Text>
        <Block factor={0.2}>
          <Text
            opacity={1}
            size={TEXT_SIZE}
            color="#181919"
            position={[
              indexTextPosition,
              (w * RESPONSIVE_SIZE) / aspect / 1.5,
              -5,
            ]}
          >
            {indexText}
          </Text>
        </Block>
        <Block factor={0.2}>
          {index === 0 && (
            <Html
              center
              occlude="blending"
              position={[-1, (w * RESPONSIVE_SIZE) / aspect / 1.125, -1]}
              style={{
                width: `${w * RESPONSIVE_SIZE * 75}px`,
                opacity: inView ? 1 : 0,
                transition: 'all 0.3s ease',
              }}
            >
              <TimelineLightning />
            </Html>
          )}
        </Block>
      </group>
    </Block>
  );
}
