import CircleDescription from '../components/circleDescription';
import ScrollToTopButton from '../components/scrollToTopButton';

export const logo = {
  svg: 'M 191.986 71.65 L 209.548 53.338 M 251.328 277.857 L 263.29 246.679 L 209.53 53.451 L 35.131 7.452 L 12.316 21.724 M 247.162 462.978 L 253.746 444.436 L 177.97 259.143 M 13.103 22.204 L 191.891 71.554 L 250.526 277.53 L 161.516 255.126 L 247.19 463.486 L 8.037 169.194 L 105.049 189.286 L 13.103 22.204 Z M 90.664 163.47 L 24.438 151.214 L 6.688 168.619',
  viewBox: '3.5 1.8 265 465',
};

export const scrollingOverlayElements = [
  {
    component: ScrollToTopButton,
    id: 'ScrollToTopButton',
    className: 'scrollToTopButton',
    props: {}, // Add any props needed for ScrollToTopButton
  },
  {
    component: CircleDescription,
    id: 'CircleDescription',
    className: 'circleDescription',
    props: {
      fill: 'white', // Add any props needed for CircleDescription
    },
  },
];
