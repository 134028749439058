import React from 'react';

const CircleElectricParticle = ({ props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        preserveAspectRatio="none"
        viewBox="0 0 234.6 61.3"
        {...props}
      >
        <path
          fill="transparent"
          stroke="#fff"
          filter="url(#glow)"
          d="M216.3 51.2c-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 6.8-7.3 6.8-3.7 0-3.7-4.6-7.3-4.6-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-.9-7.3-.9-3.7 0-3.7-2.7-7.3-2.7-3.7 0-3.7 7.8-7.3 7.8-3.7 0-3.7-4.9-7.3-4.9-3.7 0-3.7-7.8-7.3-7.8-3.7 0-3.7-1.1-7.3-1.1-3.7 0-3.7 3.1-7.3 3.1-3.7 0-3.7 10.9-7.3 10.9-3.7 0-3.7-12.5-7.3-12.5-3.7 0-3.7 4.6-7.3 4.6-3.7 0-3.7 4.5-7.3 4.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-10-7.3-10-3.7 0-3.7-.4-7.3-.4-3.7 0-3.7 2.3-7.3 2.3-3.7 0-3.7 7.1-7.3 7.1-3.7 0-3.7-11.2-7.3-11.2-3.7 0-3.7 3.5-7.3 3.5-3.7 0-3.7 3.6-7.3 3.6-3.7 0-3.7-2.9-7.3-2.9-3.7 0-3.7 8.4-7.3 8.4-3.7 0-3.7-14.6-7.3-14.6-3.7 0-3.7 5.8-7.3 5.8-2.2 0-3.8-.4-5.5-1.5-1.8-1.1-1.8-2.9-2.9-4.8-1-1.8 1.9-2.7 1.9-4.8 0-3.4-2.1-3.4-2.1-6.8S.7 30.8.7 27.4s8-3.4 8-6.8c0-2.2 2.1-2.4 3.1-4.2 1.1-1.8.2-3.9 2-5 1.8-1 3.1-7.9 5.3-7.9 3.7 0 3.7.9 7.3.9 3.7 0 3.7 6.7 7.3 6.7 3.7 0 3.7-1.8 7.3-1.8 3.7 0 3.7-.6 7.3-.6C52 8.7 52 .9 55.6.9h7.3c3.7 0 3.7 4.7 7.3 4.7 3.7 0 3.7-1.1 7.3-1.1 3.7 0 3.7 11.6 7.3 11.6 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-12.9 7.3-12.9 3.7 0 3.7 10.9 7.3 10.9 3.7 0 3.7 1.3 7.3 1.3 3.7 0 3.7-8.7 7.3-8.7 3.7 0 3.7 11.5 7.3 11.5 3.7 0 3.7-1.4 7.3-1.4 3.7 0 3.7-2.6 7.3-2.6 3.7 0 3.7-5.8 7.3-5.8 3.7 0 3.7-1.3 7.3-1.3 3.7 0 3.7 6.6 7.3 6.6s3.7-9.3 7.3-9.3c3.7 0 3.7.2 7.3.2 3.7 0 3.7 8.5 7.3 8.5 3.7 0 3.7.2 7.3.2 3.7 0 3.7-1.5 7.3-1.5 3.7 0 3.7 1.6 7.3 1.6s3.7-5.1 7.3-5.1c2.2 0 .6 9.6 2.4 10.7s4.1-2 5.1-.1c1 1.8 10.3 2.2 10.3 4.3 0 3.4-10.7 3.4-10.7 6.8s1.2 3.4 1.2 6.8 1.9 3.4 1.9 6.8c0 2.2 7.2 7.7 6.2 9.5-1.1 1.8-12.3-6.5-14.1-5.5-1.7.9-.1 6.2-2.2 6.2z"
          className="voltage line-1"
        />
        <path
          fill="transparent"
          stroke="#fff"
          filter="url(#glow)"
          d="M216.3 52.1c-3 0-3-.5-6-.5s-3 3-6 3-3-2-6-2-3 1.6-6 1.6-3-.4-6-.4-3-1.2-6-1.2-3 3.4-6 3.4-3-2.2-6-2.2-3-3.4-6-3.4-3-.5-6-.5-3 1.4-6 1.4-3 4.8-6 4.8-3-5.5-6-5.5-3 2-6 2-3 2-6 2-3 1.6-6 1.6-3-4.4-6-4.4-3-.2-6-.2-3 1-6 1-3 3.1-6 3.1-3-4.9-6-4.9-3 1.5-6 1.5-3 1.6-6 1.6-3-1.3-6-1.3-3 3.7-6 3.7-3-6.4-6-6.4-3 2.5-6 2.5h-6c-3 0-3-.6-6-.6s-3-1.4-6-1.4-3 .9-6 .9-3 4.3-6 4.3-3-3.5-6-3.5c-2.2 0-3.4-1.3-5.2-2.3-1.8-1.1-3.6-1.5-4.6-3.3S4.1 43 4.1 40.8c0-3.4.4-3.4.4-6.8s2.9-3.4 2.9-6.8-.8-3.4-.8-6.8c0-2.2.3-4.2 1.3-5.9 1.1-1.8.8-6.2 2.6-7.3 1.8-1 5.5-2 7.7-2 3 0 3 2 6 2s3-.5 6-.5 3 5.1 6 5.1 3-1.1 6-1.1 3-5.6 6-5.6 3 4.8 6 4.8 3 .6 6 .6 3-3.8 6-3.8 3 5.1 6 5.1 3-.6 6-.6 3-1.2 6-1.2 3-2.6 6-2.6 3-.6 6-.6 3 2.9 6 2.9 3-4.1 6-4.1 3 .1 6 .1 3 3.7 6 3.7 3 .1 6 .1 3-.6 6-.6 3 .7 6 .7 3-2.2 6-2.2 3 4.4 6 4.4 3-1.7 6-1.7 3-4 6-4 3 4.7 6 4.7 3-.5 6-.5 3-.8 6-.8 3-3.8 6-3.8 3 6.3 6 6.3 3-4.8 6-4.8 3 1.9 6 1.9 3-1.9 6-1.9 3 1.3 6 1.3c2.2 0 5-.5 6.7.5 1.8 1.1 2.4 4 3.5 5.8 1 1.8.3 3.7.3 5.9 0 3.4 3.4 3.4 3.4 6.8s-3.3 3.4-3.3 6.8 4 3.4 4 6.8c0 2.2-6 2.7-7 4.4-1.1 1.8 1.1 6.7-.7 7.7-1.6.8-4.7-1.1-6.8-1.1z"
          className="voltage line-2"
        />
      </svg>
    </>
  );
};

const LightningEffect = () => (
  <>
    <CircleElectricParticle />
    <div className="dots">
      {[1, 2, 3, 4, 5].map((index) => (
        <div
          key={`dot-${index}`}
          className={`dot dot-${index}`}
        ></div>
      ))}
    </div>
  </>
);

export default LightningEffect;
