import React, { useMemo } from 'react';
import { useLoader } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { TextureLoader, LinearFilter } from 'three';

import state from '../../../ui/const/store';
import { Block, useBlock } from '../../../ui/components/blocks';
import ScrollDown from '../../../ui/components/scrollDown';

import { Text, MultilineText } from './Text';
import Plane from './Plane';
import ParagraphWithImage from './ParagraphImage';
import ParagraphHtml from './ParagraphHtml';
import HeroLightning from './HeroLightning';

export default function Content() {
  const { contentMaxWidth: w, canvasWidth, mobile, canvasHeight } = useBlock();
  const images = useLoader(
    TextureLoader,
    state.paragraphImage.map(({ image }) => image),
  );
  useMemo(
    () => images.forEach((texture) => (texture.minFilter = LinearFilter)),
    [images],
  );

  return (
    <>
      <Block
        factor={1}
        offset={0}
      >
        <Block factor={1.2}>
          <Text
            left
            size={w * 0.075}
            position={[mobile ? -canvasWidth / 2 + 0.5 : -0.7, 2.1, 0]}
            color="#FFB727"
          >
            Empowering
          </Text>
          <Text
            left
            size={w * 0.075}
            position={[mobile ? -canvasWidth / 2 + 0.5 : -0.8, 0.5, 0]}
            color="#FFB727"
          >
            Through Code
          </Text>
          <Html
            style={{ color: '#fff' }}
            position={[mobile ? -canvasWidth / 2 + 0.5 : -0.65, -0.75, 0]}
            occlude
            prepend
            castShadow // Make HTML cast a shadow
            receiveShadow // Make HTML receive shadows
          >
            <h1
              className="largeText"
              tabIndex={0}
            >
              I'm Olli, a passionate{' '}
              <span className="underlined">Full-Stack Web Developer</span> based
              in Berlin.
              <br />
              My job is to empower projects by crafting awesome web solutions.
              <br />
              Let&apos;s make an impact, together.
            </h1>
            <HeroLightning className="lightningFlash" />
          </Html>
        </Block>

        <Block factor={1.0}>
          <Html
            className="scrollDown"
            position={[-canvasWidth / 2 + 0.75, -5, 0]}
          >
            <ScrollDown
              factor={4}
              tabIndex={0}
            >
              Welcome to my personal website
              <br />
              Please scroll down for more info &#8675;
            </ScrollDown>
          </Html>
        </Block>
      </Block>
      <Block
        factor={2}
        offset={3.515}
      >
        <MultilineText
          center
          size={w * 0.125}
          lineHeight={w / 5}
          position={[0, 0.5, -1]}
          color="#fff"
          text={'imagine\nplan\ncreate'}
        />
      </Block>
      {state.paragraphImage.map((props, index) => (
        <ParagraphWithImage
          key={index}
          index={index}
          {...props}
          image={images[index]}
        />
      ))}
      {state.paragraphText.map((props, index) => (
        <ParagraphHtml
          key={index}
          index={index}
          {...props}
        />
      ))}
      {state.stripes.map(({ offset, backgroundColor, height }, index) => (
        <Block
          key={index}
          factor={-1.5}
          offset={offset}
        >
          <Plane
            args={[50, height, 32, 32]}
            shift={-4}
            color={backgroundColor}
            rotation={[0, 0, Math.PI / 8]}
            position={[0, 0, -4]}
          />
        </Block>
      ))}
      <Block
        factor={1.25}
        offset={7}
      >
        <Html
          style={{ color: 'white' }}
          className="bottom-left"
          position={[-canvasWidth / 2, -canvasHeight / 2, 0]}
        >
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr
        </Html>
      </Block>
    </>
  );
}
