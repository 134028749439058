import React from 'react';
import { Outlet } from 'react-router-dom';
import { animated, config, useTrail } from 'react-spring';

import LayoutHeader from './layoutHeader';
import { scrollingOverlayElements } from '../const/data';

import '../styles/base.scss';

export default function Layout() {
  const trail = useTrail(scrollingOverlayElements.length, {
    config: config.gentle,
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 3000, // Apply staggered delay to all elements in scrollingOverlay
  });

  return (
    <>
      <LayoutHeader />
      <div className="scrollingOverlay">
        {scrollingOverlayElements.map((element, index) => {
          const ElementComponent = element.component;
          return (
            <animated.div
              key={index}
              id={element.id}
              className={element.className}
              style={trail[index]}
            >
              <ElementComponent {...element.props} />
            </animated.div>
          );
        })}
      </div>
      <main className="mainContent">
        <Outlet />
      </main>
    </>
  );
}
