import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

export default function ScrollDownCTA({ children, factor, tabIndex }) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const scrollArea = document.getElementById('ScrollArea');

    const handleScroll = () => {
      const scrollTop = scrollArea.scrollTop;
      setScrollPosition(scrollTop);
    };

    // Attach scroll event listener
    scrollArea.addEventListener('scroll', handleScroll);

    // Cleanup: Remove the scroll event listener when the component unmounts
    return () => {
      scrollArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Calculate whether to show the container based on the scroll position
  const shouldShowContainer = scrollPosition <= 100;

  const { opacityStyle, pointerEventStyle } = useSpring({
    opacityStyle: shouldShowContainer ? 1 : 0,
    pointerEventStyle: shouldShowContainer ? 'all' : 'none',
    config: {
      mass: 3 * factor,
      tension: 50 * factor,
      friction: 30 * factor,
      damping: 1 * factor,
    },
  });

  return (
    <animated.div
      className="chevron__container chevron__container--down"
      style={{
        opacity: opacityStyle.to((o) => o),
        pointerEvents: pointerEventStyle.to((o) => o),
      }}
    >
      <div className="chevron" />
      <div className="chevron" />
      <div className="chevron" />
      <span
        className="text"
        tabIndex={tabIndex}
      >
        {children}
      </span>
    </animated.div>
  );
}
