import React, { Suspense, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Html } from '@react-three/drei';

import Startup from '../../ui/components/startUp';
import Content from './components/Content';
import Diamonds from './components/diamonds';
import LoadingSvg from '../../ui/components/loading';

import state from '../../ui/const/store';
import '../../ui/styles/base.scss';

export default function LandingPageCompnent() {
  const scrollArea = useRef();

  const onScroll = (e) => {
    state.top.current = e.target.scrollTop;
  };

  useEffect(() => {
    void onScroll({ target: scrollArea.current });
  }, []);

  const sectionData = [
    { index: 0, name: 'Start', height: '450px' },
    { index: 1, name: 'About', height: '962px' },
    { index: 2, name: 'Experience', height: '1350px' },
    { index: 3, name: 'Principles', height: '1245px' },
    { index: 4, name: 'Skills', height: '780px' },
    { index: 5, name: 'Contact', height: '705px' },
  ];

  return (
    <>
      <Canvas
        linear
        dpr={[1, 2]}
        orthographic
        camera={{ zoom: state.zoom, position: [0, 0, 500] }}
        className="threeCanvas"
      >
        <Suspense
          fallback={
            <Html
              center
              className="loading"
            >
              <LoadingSvg
                className="loading-svg"
                stroke="white"
                fill="none"
              />
            </Html>
          }
        >
          <Startup />
          <Content />
          <Diamonds />
        </Suspense>
      </Canvas>
      <div
        id="ScrollArea"
        className="scrollArea"
        ref={scrollArea}
        onScroll={onScroll}
      >
        {new Array(state.sections).fill().map((_, index) => {
          const { name, height } = sectionData[index] || {
            name: '',
            height: '100vh',
          };

          return (
            <div
              key={index}
              id={name}
              style={{ height }}
            />
          );
        })}
      </div>
    </>
  );
}
