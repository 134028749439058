import { Link } from 'react-router-dom';

export default function NoMatch404() {
  return (
    <div className="page">
      <h1 style={{ color: 'white', fontSize: '5rem' }}>Error 404</h1>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
